var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"rounded elevation-3 bg-white h-full",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"header"},[_vm._v("Stock Value Report")])])],1),_c('v-row',{staticClass:"mt-5",attrs:{"no-gutters":"","justify":"end"}},[_c('v-col',{attrs:{"cols":"12","lg":"auto"}},[_c('v-btn',{staticClass:"mr-1",attrs:{"color":"secondary","disabled":!_vm.reports.values.length || !_vm.reports.costs.length},on:{"click":function($event){return _vm.exportDataToCSV()}}},[_vm._v(" Export ")]),_c('v-btn',{attrs:{"color":"primary","loading":_vm.reports.status === 'preparing'},on:{"click":function($event){return _vm.getLastedReport()}}},[_c('v-icon',[_vm._v(" mdi-refresh ")])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"updated mt-2"},[_vm._v(" Updated at "+_vm._s(_vm.getDateFormat(_vm.reports.updatedAt))+" ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headersValue,"items":_vm.reports.values,"items-per-page":-1,"dense":"","hide-default-footer":"","no-data-text":_vm.getNoDataText(),"loading-text":"Waiting..."},scopedSlots:_vm._u([{key:"item.warehouse",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.warehouse.name)+" ")]}},{key:"item.all",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("showNumberFormat")(item.all))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("showNumberFormat")(item.price))+" ")]}},{key:"item.specialPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("showNumberFormat")(item.specialPrice))+" ")]}},{key:"item.club",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("showNumberFormat")(item.club))+" ")]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"header"},[_vm._v("Stock Cost Report")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headersCost,"items":_vm.reports.costs,"items-per-page":-1,"dense":"","hide-default-footer":"","no-data-text":_vm.getNoDataText(),"loading-text":"Waiting..."},scopedSlots:_vm._u([{key:"item.warehouse",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.warehouse.name)+" ")]}},{key:"item.all",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("showNumberFormat")(item.all))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("showNumberFormat")(item.price))+" ")]}},{key:"item.specialPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("showNumberFormat")(item.specialPrice))+" ")]}},{key:"item.club",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("showNumberFormat")(item.club))+" ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }