import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class StockCostValueProvider extends HttpRequest {
  constructor () {
    super(process.env.VUE_APP_MER_API)
  }

  getStockCostValue () {
    this.setHeader(getAuthToken())
    return this.get('stock-cost-value')
  }

  getLastedStockCostValue () {
    this.setHeader(getAuthToken())
    return this.get('stock-cost-value/get-lasted')
  }
}

export default StockCostValueProvider
