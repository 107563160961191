<template>
  <v-container
    class="rounded elevation-3 bg-white h-full"
    fluid>
    <v-row>
      <v-col cols="12">
        <span class="header">Stock Value Report</span>
      </v-col>
    </v-row>
    <v-row
      class="mt-5"
      no-gutters
      justify="end">
      <v-col
        cols="12"
        lg="auto">
        <v-btn
          class="mr-1"
          color="secondary"
          :disabled="!reports.values.length || !reports.costs.length"
          @click="exportDataToCSV()">
          Export
        </v-btn>
        <v-btn
          color="primary"
          :loading="reports.status === 'preparing'"
          @click="getLastedReport()">
          <v-icon>
            mdi-refresh
          </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <span class="updated mt-2">
          Updated at {{ getDateFormat(reports.updatedAt) }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headersValue"
          :items="reports.values"
          :items-per-page="-1"
          dense
          hide-default-footer
          :no-data-text="getNoDataText()"
          loading-text="Waiting...">
          <template #[`item.warehouse`]="{ item }">
            {{ item.warehouse.name }}
          </template>
          <template #[`item.all`]="{ item }">
            {{ item.all | showNumberFormat() }}
          </template>
          <template #[`item.price`]="{ item }">
            {{ item.price | showNumberFormat() }}
          </template>
          <template #[`item.specialPrice`]="{ item }">
            {{ item.specialPrice | showNumberFormat() }}
          </template>
          <template #[`item.club`]="{ item }">
            {{ item.club | showNumberFormat() }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row class="mt-5">
      <v-col cols="12">
        <span class="header">Stock Cost Report</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headersCost"
          :items="reports.costs"
          :items-per-page="-1"
          dense
          hide-default-footer
          :no-data-text="getNoDataText()"
          loading-text="Waiting...">
          <template #[`item.warehouse`]="{ item }">
            {{ item.warehouse.name }}
          </template>
          <template #[`item.all`]="{ item }">
            {{ item.all | showNumberFormat() }}
          </template>
          <template #[`item.price`]="{ item }">
            {{ item.price | showNumberFormat() }}
          </template>
          <template #[`item.specialPrice`]="{ item }">
            {{ item.specialPrice | showNumberFormat() }}
          </template>
          <template #[`item.club`]="{ item }">
            {{ item.club | showNumberFormat() }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { ExportToCsv } from 'export-to-csv'
import StockCostValueProvider from '@/resources/StockCostValueProvider'

const StockCostValueService = new StockCostValueProvider()

export default {
  data () {
    return {
      headersValue: [
        {
          text: 'สาขา',
          value: 'warehouse',
          sortable: false,
          align: 'left'
        },
        {
          text: 'ทั้งหมด',
          value: 'all',
          sortable: false,
          align: 'right'
        },
        {
          text: 'สินค้าปกติ',
          value: 'price',
          sortable: false,
          align: 'right'
        },
        {
          text: 'สินค้า Special Price',
          value: 'specialPrice',
          sortable: false,
          align: 'right'
        },
        {
          text: 'สินค้า Club',
          value: 'club',
          sortable: false,
          align: 'right'
        }
      ],
      headersCost: [
        {
          text: 'สาขา',
          value: 'warehouse',
          sortable: false,
          align: 'left'
        },
        {
          text: 'ทั้งหมด',
          value: 'all',
          sortable: false,
          align: 'right'
        },
        {
          text: 'สินค้าปกติ',
          value: 'price',
          sortable: false,
          align: 'right'
        },
        {
          text: 'สินค้า Special Price',
          value: 'specialPrice',
          sortable: false,
          align: 'right'
        },
        {
          text: 'สินค้า Club',
          value: 'club',
          sortable: false,
          align: 'right'
        }
      ],
      reports: {
        values: [],
        costs: [],
        status: ''
      }
    }
  },
  mounted () {
    this.getReports()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading'
    }),
    async getReports () {
      try {
        this.setLoading({ active: true })

        const { data } = await StockCostValueService.getStockCostValue()

        this.reports = data
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[GET-REPORT-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    async getLastedReport () {
      try {
        this.setLoading({ active: true })

        const { data } = await StockCostValueService.getLastedStockCostValue()

        this.reports.values = data?.values || []
        this.reports.costs = data?.costs || []
        this.reports.status = data?.status || ''
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[GET-REPORT-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    getDateFormat (date) {
      return this.$dayjs(date).format('DD/MM/YYYY HH:mm')
    },
    exportDataToCSV () {
      this.exportStockValueToCSV()
      this.exportStockCostToCSV()
    },
    async exportStockValueToCSV () {
      try {
        this.setLoading({ active: true })

        const { data } = await StockCostValueService.getStockCostValue()

        const items = data.values.map((report) => ({
          warehouse: report.warehouse.name,
          all: report.all,
          price: report.price,
          specialPrice: report.specialPrice,
          club: report.club
        }))

        const options = {
          filename: 'Stock-Value',
          showLabels: true,
          useKeysAsHeaders: true
        }

        const csvExporter = new ExportToCsv(options)
        csvExporter.generateCsv(items)
      } catch (error) {
        console.error('exportStockValueToCSV', error)
        this.setSnackbar({
          value: true,
          message: `Error ${error.code} : ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    async exportStockCostToCSV () {
      try {
        this.setLoading({ active: true })

        const { data } = await StockCostValueService.getStockCostValue()

        const items = data.costs.map((report) => ({
          warehouse: report.warehouse.name,
          all: report.all,
          price: report.price,
          specialPrice: report.specialPrice,
          club: report.club
        }))

        const options = {
          filename: 'Stock-Cost',
          showLabels: true,
          useKeysAsHeaders: true
        }

        const csvExporter = new ExportToCsv(options)
        csvExporter.generateCsv(items)
      } catch (error) {
        console.error('exportStockCostToCSV', error)
        this.setSnackbar({
          value: true,
          message: `Error ${error.code} : ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    getNoDataText () {
      if (this.reports.status === 'preparing') {
        return 'Getting lasted data in progress...'
      }

      return 'Empty'
    }
  }
}
</script>

<style scoped>
.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}
.header {
  font-size: 1.5rem;
  font-weight: bold;
}
.bg-white {
  background-color: white !important;
}
.updated {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  color: gray;
}
</style>
